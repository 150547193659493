import React, {useRef, useState} from "react";
import './Home.scss'

const images_header = require.context('../../images-header', false, /\.(png|jpe?g|svg)$/);
const shoe_images = require.context('./images/shoe_images', false, /\.(png|jpe?g|svg)$/);
const mark_logos = require.context('./images/mark_logos', false, /\.(png|jpe?g|svg)$/);
const images = require.context('./images', false, /\.(png|jpe?g|svg)$/);

const bestsellers = [
    {
        mark_logo: mark_logos('./jordan.svg'),
        shoe_image: shoe_images('./Jordan4-Military-Black.svg'),
        mark: "Jordan 4",
        model: "Military Black",
        size: "43"
    },
    {
        mark_logo: mark_logos('./yeezy.svg'),
        shoe_image: shoe_images('./Yeezy-Boost-350-Zebra.svg'),
        mark: "Yeezy Boost 350",
        model: "Zebra",
        size: "na zamówienie"
    },{
        mark_logo: mark_logos('./nike.svg'),
        shoe_image: shoe_images('./Nike-Dunk-Low-Panda.svg'),
        mark: "Nike Dunk Low",
        model: "Panda",
        size: "39, 40, 42"
    },
    {
        mark_logo: mark_logos('./yeezy.svg'),
        shoe_image: shoe_images('./Yeezy-Slides-Onyx.svg'),
        mark: "Yeezy Slides",
        model: "Onyx",
        size: "42, 44.5"
    },
    {
        mark_logo: mark_logos('./jordan.svg'),
        shoe_image: shoe_images('./Jordan4-Frozen-Moments.svg'),
        mark: "Jordan 4",
        model: "Frozen Moments",
        size: "41, 43"
    },
    {
        mark_logo: mark_logos('./jordan.svg'),
        shoe_image: shoe_images('./Jordan1-High-Taxi.svg'),
        mark: "Jordan 1",
        model: "High Taxi",
        size: "na zamówienie"
    }
]

const Home = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const bestsellersToShow = 4;

    const AboutUsRef = useRef(null);
    const scrollToRef = (ref) => window.scrollTo({ top: ref.current.offsetTop + 30, behavior: 'smooth' });
    const handleRightArrowClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % bestsellers.length);
    };

    const handleLeftArrowClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + bestsellers.length) % bestsellers.length);
    };

    const displayedBestsellers = bestsellers.slice(currentIndex, currentIndex + bestsellersToShow).concat(bestsellers.slice(0, Math.max(bestsellersToShow - (bestsellers.length - currentIndex), 0)));

    return (
        <div className="home-page">
            <div className="background-color"></div>
            <header>
                <img src={images_header("./logo.png")}/>
                <div className="links_and_lines">
                    <div className="line"></div>
                    <div className="links">
                        <a onClick={() => window.location.href = '/'}>Home</a>
                        <a onClick={() => window.location.href = '/products'}>Products</a>
                        {/*<div className="mark">*/}
                        {/*    <a>Mark</a>*/}
                        {/*    <img src={images_header('./mark-arrow.png')}/>*/}
                        {/*</div>*/}
                        <a>Contact</a>
                        <a className="sale">Sale</a>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="account_and_shopping">
                    <img src={images_header("./account.svg")}/>
                    <img src={images_header("./shopping.svg")}/>
                </div>
            </header>
            <div className="head-lines">
                <div className="head-line left"></div>
                <div className="head-line right"></div>
            </div>
            <section className="first-look">
                <div className="image_and_texts">
                    <div className="texts_and_button">
                        <h1>Witaj w Sniks Plug!</h1>
                        <p>Odkryj buty, które definiują Twój styl. Od ekskluzywnych sneakersów po eleganckie klasyki —
                            znajdź swoją idealną parę w naszym sklepie.</p>
                        <button onClick={() => scrollToRef(AboutUsRef)}>
                            <a onClick={() => scrollToRef(AboutUsRef)}>DOWIEDZ SIĘ WIĘCEJ</a>
                        </button>
                    </div>
                    <img src={images('./Jordan-High-Taxi.png')}/>
                </div>
                <div className="wave-container">
                    <svg className="wave-svg" viewBox="0 0 1200 150" preserveAspectRatio="none">
                        <g transform="scale(1,-1) translate(0,-150)">
                            <path className="wave-path"
                                  d="M0,95 C200,0 300,100 400,95 C1000,0 1000,100 1200,75 L1200,0 L0,0 Z"></path>
                        </g>
                    </svg>
                </div>
                <div className="wave-container nr2">
                    <svg className="wave-svg" viewBox="0 0 1200 150" preserveAspectRatio="none">
                        <g transform="scale(1,-1) translate(0,-150)">
                            <path className="wave-path"
                                  d="M0,95 C200,0 300,100 400,95 C1000,0 1000,100 1200,75 L1200,0 L0,0 Z"></path>
                        </g>
                    </svg>
                </div>
            </section>
            <section className="bestsellers">
                <div className="background-image"></div>
                <div className="text">
                    <h2>Bestsellers</h2>
                    <div className="line"></div>
                </div>
                <div className="products_and_button">
                    <div className="products_and_images">
                        <img src={images_header('./mark-arrow.png')} className="arrow left"
                             onClick={handleLeftArrowClick}/>
                        <div className="products">
                            {displayedBestsellers.map((product, index) => (
                                <div className="product" key={index}>
                                    <img src={product.mark_logo}
                                         className={`mark-logo ${product.mark_logo === mark_logos('./yeezy.svg') ? 'yeezy' : ''}`}/>
                                    <img src={product.shoe_image} className="shoe-image"/>
                                    <div className="product-info">
                                        <div className="mark_and_model">
                                            <h3>{product.mark}</h3>
                                            <h3>{product.model}</h3>
                                        </div>
                                        <h4>Rozmiar {product.size}</h4>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <img src={images_header('./mark-arrow.png')} className="arrow right"
                             onClick={handleRightArrowClick}/>
                    </div>
                    <button className="button nr2" onClick={() => window.location.href = '/products'}>
                        <a onClick={() => window.location.href = '/products'}>ZOBACZ WSZYSTKIE</a>
                    </button>
                </div>
                <div className="wave-container nr3">
                    <svg className="wave-svg" viewBox="0 0 1200 150" preserveAspectRatio="none">
                        <g transform="scale(1,-1) translate(0,-150)">
                            <path className="wave-path"
                                  d="M0,95 C200,0 300,100 400,95 C1000,0 1000,100 1200,75 L1200,0 L0,0 Z"></path>
                        </g>
                    </svg>
                </div>
            </section>
            <section ref={AboutUsRef} className="about-us">
                <div className="line"></div>
                <div className="image_and_texts">
                    <img src={images('./Jordan-High-Taxi.png')}/>
                    <div className="texts">
                        <div className="text">
                            <h2>O nas</h2>
                            <div className="line"></div>
                        </div>
                        <div className="paragraphs">
                            <p>Nasza historia zaczyna się od marzenia młodego chłopaka, który postawił sobie za cel nie
                                tylko śledzić trendy, ale tworzyć przestrzeń, gdzie każdy może znaleźć obuwie, które
                                idealnie oddaje jego indywidualność. Założyciel Sniks Plug, zafascynowany światem mody i
                                determinowany, by stać się najlepszym w branży, rozpoczął swoją podróż z pasją i wizją,
                                które kierowały każdym jego krokiem.</p>
                            <p>Wychodząc naprzeciw wyzwaniom, z niezachwianą wiarą w swoje marzenia, zbudował Sniks Plug
                                od podstaw. Jego poświęcenie, godziny spędzone na poznawaniu potrzeb klientów,
                                selekcjonowaniu najlepszych produktów i budowaniu zaufania, zaowocowały miejscem, gdzie
                                każdy pasjonat obuwia może znaleźć coś dla siebie. Sniks Plug to nie tylko sklep, to
                                realizacja marzenia o tworzeniu społeczności, która dzieli wspólną pasję do obuwia,
                                stylu i wyrażania siebie.</p>
                            <p>Za każdym parą butów, którą oferujemy, stoi historia, determinacja i pasja założyciela,
                                który wierzy, że każdy z nas może znaleźć swój wyjątkowy sposób, by wyrazić siebie. W
                                Sniks Plug celebrujemy indywidualność, oferując produkty, które pomagają naszym klientom
                                kroczyć przez życie z pewnością siebie i stylem, który jest tylko ich własny.</p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="footer-line"></div>
            <footer>
                <img src={images_header("./logo.png")}/>
                <div className="links">
                    <div className="contact">
                        <h4>Contact</h4>
                        <div>
                            <img src={images('./email.png')} alt="Email icons created
                                by Freepik - Flaticon https://www.flaticon.com/free-icons/email "/>
                            <p>sniks.plug@gmail.com</p>
                        </div>
                    </div>
                    <div className="social-media">
                        <h4>Social media</h4>
                        <div>
                            <img src={images('./instagram.png')} alt="Instagram
                                icons created by Freepik - Flaticon https://www.flaticon.com/free-icons/instagram"/>
                            <p>sniks.plug</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Home;