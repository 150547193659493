import { BrowserRouter, Path, Routes, Route, Router } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Home from "./components/pages/Home/Home";
import Products from "./components/pages/Products/Products";
import Logo from './components/images-header/logo.png'
import './App.css';

function App() {
  return (
    <div className="App">
        <Helmet>
            <title>Sniks Plug</title>
            <link rel="icon" type="image/png" href={Logo} sizes="500x500"/>
        </Helmet>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
