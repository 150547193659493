import React, {useEffect, useState} from "react";
import './Product.scss'

const images_header = require.context('../../images-header', false, /\.(png|jpe?g|svg)$/);
const images = require.context('../Home/images', false, /\.(png|jpe?g|svg)$/);
const shoe_images = require.context('../Home/images/shoe_images', false, /\.(png|jpe?g|svg)$/);
const mark_logos = require.context('../Home/images/mark_logos', false, /\.(png|jpe?g|svg)$/);



const Products = () => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isShoesTypeVisible, setIsShoesTypeVisible] = useState(false);
    const [isShoesPriceVisible, setIsShoesPriceVisible] = useState(false);
    const [isShoesSizeVisible, setIsShoesSizeVisible] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [minSize, setMinSize] = useState('');
    const [maxSize, setMaxSize] = useState('');
    const [selectedMainImage, setSelectedMainImage] = useState(null);
    const [selectedColor, setSelectedColor] = useState("");
    const [isColorVisible, setIsColorVisible] = useState(false);
    useEffect(() => {
        // Ustaw główne zdjęcie produktu jako domyślne zdjęcie główne
        // za każdym razem, gdy zmienia się selectedProduct
        if (selectedProduct) {
            setSelectedMainImage(selectedProduct.shoe_image);
        }
    }, [selectedProduct]);

    const handleImageClick = (image) => {
        setSelectedMainImage(image);
    };

    const products = [
        {
            mark_logo: mark_logos('./jordan.svg'),
            shoe_image: shoe_images('./Jordan4-Military-Black.svg'),
            shoe_images: [
                shoe_images('./Jordan4-Military-Black.svg'),
                shoe_images('./Jordan4-Military-Black-Front.svg'),
                shoe_images('./Jordan4-Military-Black-Back.svg'),
                shoe_images('./Jordan4-Military-Black-Bottom.svg')
            ],
            color: ['white', 'black'],
            mark: "Jordan 4",
            model: "Military Black",
            info: "Jordan 4 „Military Black” to stylowa i elegancka interpretacja jednego z najbardziej kultowych modeli butów koszykarskich. Ta wersja czerpie inspirację z klasycznej kolorystyki „Military Blue”, jednak zastępuje błękitne akcenty subtelnymi czarnymi detalami, co nadaje butom nowoczesny i wszechstronny wygląd. ",
            size: "43",
            sizes: [
                {
                    shoe_size: 43,
                    price: 700
                }
            ]

        },
        {
            mark_logo: mark_logos('./yeezy.svg'),
            shoe_image: shoe_images('./Yeezy-Boost-350-Zebra.svg'),
            shoe_images: [
                shoe_images('./Yeezy-Boost-350-Zebra.svg'),
                shoe_images('./Yeezy-Boost-350-Zebra-Front.svg'),
                shoe_images('./Yeezy-Boost-350-Zebra-Back.svg'),
                shoe_images('./Yeezy-Boost-350-Zebra-Bottom.svg')
            ],
            color: ['white', 'black'],
            mark: "Yeezy Boost 350",
            model: "Zebra",
            info: "Adidas Yeezy Boost 350 \"Zebra\" to jeden z najbardziej rozpoznawalnych i pożądanych modeli w linii Yeezy, zaprojektowanych przez Kanye Westa. Charakterystyczną cechą tego modelu jest jego wyjątkowy, \"zebrany\" wzór, który łączy ostre kontrasty białych i czarnych pasów na elastycznej cholewce z materiału Primeknit.",
            size: "na zamówienie",
            sizes: [
                {
                    shoe_size: "na zamówienie"
                }
            ]
        },{
            mark_logo: mark_logos('./nike.svg'),
            shoe_image: shoe_images('./Nike-Dunk-Low-Panda.svg'),
            shoe_images: [
                shoe_images('./Nike-Dunk-Low-Panda.svg'),
                shoe_images('./Nike-Dunk-Low-Panda-Front.svg'),
                shoe_images('./Nike-Dunk-Low-Panda-Back.svg'),
                shoe_images('./Nike-Dunk-Low-Panda-Bottom.svg')
            ],
            color: ['white', 'black'],
            mark: "Nike Dunk Low",
            model: "Panda",
            info: "Nike Dunk Low \"Black-White\", znane również jako \"Panda\", to ikoniczny model charakteryzujący się ponadczasową, monochromatyczną kolorystyką. Kontrastowe połączenie głębokiej czerni i czystej bieli nadaje tym butom wyrazisty i łatwo rozpoznawalny wygląd.",
            size: "39, 40, 42",
            sizes: [
                {
                    shoe_size: 39,
                    price: 700
                },
                {
                    shoe_size: 40,
                    price: 700
                },
                {
                    shoe_size: 42,
                    price: 700
                }
            ]
        },
        {
            mark_logo: mark_logos('./yeezy.svg'),
            shoe_image: shoe_images('./Yeezy-Slides-Onyx.svg'),
            shoe_images: [
                shoe_images('./Yeezy-Slides-Onyx.svg'),
                shoe_images('./Yeezy-Slides-Onyx-Front.svg'),
                shoe_images('./Yeezy-Slides-Onyx-Back.svg'),
                shoe_images('./Yeezy-Slides-Onyx-Bottom.svg')
            ],
            color: ['black'],
            mark: "Yeezy Slides",
            model: "Onyx",
            info: "Adidas Yeezy Slide \"Onyx\" to surowa i minimalistyczna wersja popularnego obuwia typu slide, które wyróżnia się monochromatyczną, głęboką czernią. Ta kolorystyka, inspirowana kamieniem onyks, nadaje im wyrafinowany i stylowy wygląd. Yeezy Slides są znane ze swojej wyjątkowej konstrukcji, która zapewnia niezrównany komfort.",
            size: "42, 44.5",
            sizes: [
                {
                    shoe_size: 42,
                    price: 750
                },
                {
                    shoe_size: 44.5,
                    price: 900
                }
            ]
        },
        {
            mark_logo: mark_logos('./jordan.svg'),
            shoe_image: shoe_images('./Jordan4-Frozen-Moments.svg'),
            shoe_images: [
                shoe_images('./Jordan4-Frozen-Moments.svg'),
                shoe_images('./Jordan4-Frozen-Moments-Front.svg'),
                shoe_images('./Jordan4-Frozen-Moments-Back.svg'),
                shoe_images('./Jordan4-Frozen-Moments-Bottom.svg')
            ],
            color: ['grey'],
            mark: "Jordan 4",
            model: "Frozen Moments",
            info: "Air Jordan 4 \"Frozen Moments\" to kreatywna reinterpretacja klasycznego stylu, łącząca tradycyjne motywy z nowymi, świeżymi elementami. Model ten charakteryzuje się wyjątkowym wyglądem, czerpiąc inspirację z zaczarowanych zimowych pejzaży, co manifestuje się w jego lodowatej palecie barw i detalach nawiązujących do mroźnej natury.",
            size: "41, 43",
            sizes: [
                {
                    shoe_size: 41,
                    price: 1750
                },
                {
                    shoe_size: 43,
                    price: 1750
                }
            ]
        },
        {
            mark_logo: mark_logos('./jordan.svg'),
            shoe_image: shoe_images('./Jordan1-High-Taxi.svg'),
            shoe_images: [
                shoe_images('./Jordan1-High-Taxi.svg'),
                shoe_images('./Jordan1-High-Taxi-Front.svg'),
                shoe_images('./Jordan1-High-Taxi-Back.svg'),
                shoe_images('./Jordan1-High-Taxi-Bottom.svg')
            ],
            color: ['orange', 'black'],
            mark: "Jordan 1",
            model: "High Taxi",
            info: "Jordan 1 High \"Taxi\" to dynamiczna wersja klasycznego modelu, która wyróżnia się swoją żółto-czarną kolorystyką, inspirowaną ikonicznymi taksówkami. Ten model łączy w sobie kultową sylwetkę Air Jordan 1 z wyrazistym i odważnym zestawieniem kolorów. Wykonany z wysokiej jakości skóry, zapewnia zarówno trwałość, jak i komfortowe dopasowanie.",
            size: "na zamówienie",
            sizes: [
                {
                    shoe_size: "na zamówienie",
                }
            ]
        },
        {
            mark_logo: mark_logos('./adidas.svg'),
            shoe_image: shoe_images('./Adidas-Campus-00s-Core-Black.svg'),
            shoe_images: [
                shoe_images('./Adidas-Campus-00s-Core-Black.svg'),
                shoe_images('./Adidas-Campus-00s-Core-Black-Front.svg'),
                shoe_images('./Adidas-Campus-00s-Core-Black-Back.svg'),
                shoe_images('./Adidas-Campus-00s-Core-Black-Bottom.svg')
            ],
            color: ['black'],
            mark: "Adidas Campus 00s",
            model: "Core Black",
            info: "Adidas Campus 00s \"Core Black\" to nowoczesna wersja klasycznego modelu, która utrzymana jest w eleganckiej, całkowicie czarnej kolorystyce. Zaktualizowany design zachowuje niski profil i proste linie oryginalnych Campusów, ale dodaje nowe elementy, takie jak wzmocnienia dla większej trwałości i komfortu.",
            size: "42, 44",
            sizes: [
                {
                    shoe_size: 42,
                    price: 750
                },
                {
                    shoe_size: 44,
                    price: 800
                }
            ]
        },
        {
            mark_logo: mark_logos('./adidas.svg'),
            shoe_image: shoe_images('./Adidas-Campus-00s-Core-Grey.svg'),
            shoe_images: [
                shoe_images('./Adidas-Campus-00s-Core-Grey.svg'),
                shoe_images('./Adidas-Campus-00s-Core-Grey-Front.svg'),
                shoe_images('./Adidas-Campus-00s-Core-Grey-Back.svg'),
                shoe_images('./Adidas-Campus-00s-Core-Grey-Bottom.svg')
            ],
            color: ['grey'],
            mark: "Adidas Campus 00s",
            model: "Grey White",
            info: 'Adidas Campus 00s "Grey White" to odświeżona wersja klasycznego modelu, prezentująca się w stonowanej, szaro-białej kolorystyce. Ten minimalistyczny design łączy w sobie miękki, szary nubuk lub zamsz z białymi detalami, takimi jak paski Adidas i podeszwa, co tworzy czysty i uniwersalny wygląd.',
            size: "38",
            sizes: [
                {
                    shoe_size: 38,
                    price: 750
                },
            ]
        },
        {
            mark_logo: mark_logos('./jordan.svg'),
            shoe_image: shoe_images('./Jordan4-Red-Cement.svg'),
            shoe_images: [
                shoe_images('./Jordan4-Red-Cement.svg'),
                shoe_images('./Jordan4-Red-Cement-Front.svg'),
                shoe_images('./Jordan4-Red-Cement-Back.svg'),
                shoe_images('./Jordan4-Red-Cement-Bottom.svg')
            ],
            color: ['white', 'red'],
            mark: "Jordan 4",
            model: "Red Cement",
            info: 'Jordan 4 "Red Cement" to odważna interpretacja klasycznego modelu, która czerpie inspirację z ikonicznej kolorystyki "Cement", jednej z najbardziej rozpoznawalnych i cenionych w historii linii Air Jordan. W tej wersji, tradycyjny szary cementowy wzór jest zestawiony z intensywnym czerwonym kolorem, tworząc wyrazisty i dynamiczny wygląd.',
            size: "na zamówienie",
            sizes: [
                {
                    shoe_size: 'na zamówienie'
                }
            ]
        },
        {
            mark_logo: mark_logos('./nike.svg'),
            shoe_image: shoe_images('./Air-Force1-Supreme-Black.svg'),
            shoe_images: [
                shoe_images('./Air-Force1-Supreme-Black.svg'),
                shoe_images('./Air-Force1-Supreme-Black-Front.svg'),
                shoe_images('./Air-Force1-Supreme-Black-Back.svg'),
                shoe_images('./Air-Force1-Supreme-Black-Bottom.svg')
            ],
            color: ['black'],
            mark: "Nike Air Force 1",
            model: "Supreme Black",
            info: 'Air Force 1 Supreme "Black" to kolejna wyjątkowa kolaboracja między Nike a renomowaną marką streetwearową Supreme, która przynosi klasyczny model Air Force 1 w głębokim, czarnym wydaniu. Buty te łączą w sobie ponadczasowy design Air Force 1 z ekskluzywnymi detalami charakterystycznymi dla Supreme.',
            size: "na zamówienie",
            sizes: [
                {
                    shoe_size: 'na zamówienie'
                }
            ]
        },
        {
            mark_logo: mark_logos('./nike.svg'),
            shoe_image: shoe_images('./Air-Force1-Supreme-White.svg'),
            shoe_images: [
                shoe_images('./Air-Force1-Supreme-White.svg'),
                shoe_images('./Air-Force1-Supreme-White-Front.svg'),
                shoe_images('./Air-Force1-Supreme-White-Back.svg'),
                shoe_images('./Air-Force1-Supreme-White-Bottom.svg')
            ],
            color: ['white'],
            mark: "Nike Air Force 1",
            model: "Supreme White",
            info: 'Air Force 1 Supreme "White" to ekskluzywna kolaboracja pomiędzy Nike a Supreme, która przynosi odświeżoną wersję klasycznego modelu Air Force 1. W tej limitowanej edycji, model zachowuje swój ikoniczny, niskoprofilowy design, ubrany całkowicie w czystej, białej kolorystyce, co dodaje mu uniwersalności i elegancji.',
            size: "na zamówienie",
            sizes: [
                {
                    shoe_size: 'na zamówienie'
                }
            ]
        },
        {
            mark_logo: mark_logos('./nike.svg'),
            shoe_image: shoe_images('./Air-Force1-Drake-NOCTA.svg'),
            shoe_images: [
                shoe_images('./Air-Force1-Drake-NOCTA.svg'),
                shoe_images('./Air-Force1-Drake-NOCTA-Front.svg'),
                shoe_images('./Air-Force1-Drake-NOCTA-Back.svg'),
                shoe_images('./Air-Force1-Drake-NOCTA-Bottom.svg')
            ],
            color: ['white'],
            mark: "Nike Air Force 1",
            model: "Drake NOCTA",
            info: 'Air Force 1 "Drake NOCTA" w kolorze białym to specjalna edycja kultowych sneakersów, wynikająca z kolaboracji między Nike a Drake\'em, w ramach jego marki NOCTA. Ten unikatowy model łączy klasyczny design Air Force 1 z osobistym stylem i estetyką kanadyjskiego artysty, oferując fanom i sneakerheadom coś wyjątkowego.',
            size: "45",
            sizes: [
                {
                    shoe_size: 45,
                    price: 900
                }
            ]
        },
        {
            mark_logo: mark_logos('./jordan.svg'),
            shoe_image: shoe_images('./Jordan4-Yellow-Thunder.svg'),
            shoe_images: [
                shoe_images('./Jordan4-Yellow-Thunder.svg'),
                shoe_images('./Jordan4-Yellow-Thunder-Front.svg'),
                shoe_images('./Jordan4-Yellow-Thunder-Back.svg'),
                shoe_images('./Jordan4-Yellow-Thunder-Bottom.svg')
            ],
            color: ['yellow', 'black'],
            mark: "Jordan 4",
            model: "Yellow Thunder",
            info: 'Jordan 4 "Yellow Thunder" to wyrazista i energetyczna odsłona klasycznego modelu, która przyciąga uwagę swoją jaskrawo-żółtą kolorystyką. Inspiracją dla tej wersji była prawdopodobnie popularna kolorystyka "Thunder" z Jordan 4, która pierwotnie charakteryzowała się kombinacją czerni i żółci, ale w tym przypadku, żółty kolor dominuje, nadając butom świeży i zauważalny wygląd.',
            size: "44",
            sizes: [
                {
                    shoe_size: 44,
                    price: 1500
                }
            ]
        },
        {
            mark_logo: mark_logos('./jordan.svg'),
            shoe_image: shoe_images('./Jordan4-Craft-Medium-Olive.svg'),
            shoe_images: [
                shoe_images('./Jordan4-Craft-Medium-Olive.svg'),
                shoe_images('./Jordan4-Craft-Medium-Olive-Front.svg'),
                shoe_images('./Jordan4-Craft-Medium-Olive-Back.svg'),
                shoe_images('./Jordan4-Craft-Medium-Olive-Bottom.svg')
            ],
            color: ['green', 'brown'],
            mark: "Jordan 4",
            model: "Craft Medium Olive",
            info: 'Jordan 4 "Craft Medium Olive" to elegancka odsłona klasycznego modelu, prezentująca bogate połączenie odcieni oliwkowych z subtelnymi akcentami beżu i szarości. Te sneakersy wyróżniają się precyzyjnym wykonaniem, zwracającym uwagę na detale i jakość materiałów, co podkreśla ich ekskluzywny charakter. ',
            size: "40",
            sizes: [
                {
                    shoe_size: 40,
                    price: 1150
                }
            ]
        },
        {
            mark_logo: mark_logos('./yeezy.svg'),
            shoe_image: shoe_images('./Yeezy-Boost-350-Bone.svg'),
            shoe_images: [
                shoe_images('./Yeezy-Boost-350-Bone.svg'),
                shoe_images('./Yeezy-Boost-350-Bone-Front.svg'),
                shoe_images('./Yeezy-Boost-350-Bone-Back.svg'),
                shoe_images('./Yeezy-Boost-350-Bone-Bottom.svg')
            ],
            color: ['white'],
            mark: "Yeezy Boost 350",
            model: "Bone",
            info: 'Adidas Yeezy Boost 350 "Bone" to minimalistyczny i stylowy model, który łączy innowacyjne technologie z unikatowym designem. Charakteryzujący się monochromatycznym, jasnym odcieniem "Bone", ten but oferuje subtelną, ale wyrafinowaną estetykę, idealną dla tych, którzy cenią sobie zarówno komfort, jak i modne podejście do obuwia.',
            size: "na zamówienie",
            sizes: [
                {
                    shoe_size: "na zamówienie"
                }
            ]
        },
        {
            mark_logo: mark_logos('./nike.svg'),
            shoe_image: shoe_images('./Nike-Dunk-Low-Grey-Fog.svg'),
            shoe_images: [
                shoe_images('./Nike-Dunk-Low-Grey-Fog.svg'),
                shoe_images('./Nike-Dunk-Low-Grey-Fog-Front.svg'),
                shoe_images('./Nike-Dunk-Low-Grey-Fog-Back.svg'),
                shoe_images('./Nike-Dunk-Low-Grey-Fog-Bottom.svg')
            ],
            color: ['white', 'grey'],
            mark: "Nike Dunk Low",
            model: "Grey Fog",
            info: 'Nike Dunk Low "Grey Fog" to subtelna, ale stylowa interpretacja klasycznego modelu, która charakteryzuje się monochromatyczną kolorystyką w różnych odcieniach szarości. Ten model zachowuje kultową sylwetkę Dunk Low, oferując jednocześnie świeży i czysty wygląd, dzięki zastosowaniu gładkiej skóry w jasnoszarej tonacji "Grey Fog".',
            size: "na zamówienie",
            sizes: [
                {
                    shoe_size: "na zamówienie"
                }
            ]
        },
        {
            mark_logo: mark_logos('./jordan.svg'),
            shoe_image: shoe_images('./Jordan4-Seafoam.svg'),
            shoe_images: [
                shoe_images('./Jordan4-Seafoam.svg'),
                shoe_images('./Jordan4-Seafoam-Front.svg'),
                shoe_images('./Jordan4-Seafoam-Back.svg'),
                shoe_images('./Jordan4-Seafoam-Bottom.svg')
            ],
            color: ['white', 'green'],
            mark: "Jordan 4",
            model: "Seafoam",
            info: 'Jordan 4 "Seafoam" to elegancki model butów, który łączy klasyczną sylwetkę Air Jordan 4 z odświeżającą, pastelową paletą kolorów. Jego głównym elementem jest subtelny, miętowy odcień "Seafoam", który dodaje świeżości i lekkości designowi, jednocześnie zachowując sportowy charakter. Wykonane z wysokiej jakości materiałów.',
            size: "43",
            sizes: [
                {
                    shoe_size: 43,
                    price: 1700
                }
            ]
        },
        {
            mark_logo: mark_logos('./jordan.svg'),
            shoe_image: shoe_images('./Jordan4-Midnight-Navy.svg'),
            shoe_images: [
                shoe_images('./Jordan4-Midnight-Navy.svg'),
                shoe_images('./Jordan4-Midnight-Navy-Front.svg'),
                shoe_images('./Jordan4-Midnight-Navy-Back.svg'),
                shoe_images('./Jordan4-Midnight-Navy-Bottom.svg')
            ],
            color: ['white', 'blue'],
            mark: "Jordan 4",
            model: "Midnight Navy",
            info: 'Jordan 4 "Midnight Navy" to wyrafinowana wersja klasycznego modelu, która zachwyca swoją elegancką kolorystyką. Głęboki, granatowy odcień "Midnight Navy" doskonale komponuje się z białymi i szarymi akcentami, tworząc stylowy i zarazem dynamiczny wygląd. Te buty sportowe utrzymują charakterystyczną dla Air Jordan 4 konstrukcję.',
            size: "na zamówienie",
            sizes: [
                {
                    shoe_size: "na zamówienie",
                }
            ]
        },
        {
            mark_logo: mark_logos('./jordan.svg'),
            shoe_image: shoe_images('./Jordan4-Black-Canvas.svg'),
            shoe_images: [
                shoe_images('./Jordan4-Black-Canvas.svg'),
                shoe_images('./Jordan4-Black-Canvas-Front.svg'),
                shoe_images('./Jordan4-Black-Canvas-Back.svg'),
                shoe_images('./Jordan4-Black-Canvas-Bottom.svg')
            ],
            color: ['black'],
            mark: "Jordan 4",
            model: "Black Canvas",
            info: 'Jordan 4 "Black Canvas" to nowoczesna interpretacja kultowego modelu, gdzie dominującym elementem jest tekstylna cholewka z płótna w głębokim, czarnym kolorze. Ta odważna wersja zachowuje oryginalną sylwetkę Air Jordan 4, wzbogacając ją o unikatową fakturę i matowe wykończenie, które dodają charakteru i nowoczesności.',
            size: "na zamówienie",
            sizes: [
                {
                    shoe_size: "na zamówienie",
                }
            ]
        },
        {
            mark_logo: mark_logos('./nike.svg'),
            shoe_image: shoe_images('./Nike-Dunk-Low-Photom-Dust.svg'),
            shoe_images: [
                shoe_images('./Nike-Dunk-Low-Photom-Dust.svg'),
                shoe_images('./Nike-Dunk-Low-Photom-Dust-Front.svg'),
                shoe_images('./Nike-Dunk-Low-Photom-Dust-Back.svg'),
                shoe_images('./Nike-Dunk-Low-Photom-Dust-Bottom.svg')
            ],
            color: ['white', 'grey'],
            mark: "Nike Dunk Low",
            model: "Photon Dust",
            info: 'Nike Dunk Low "Photon Dust" wyróżnia się minimalistycznym designem, łączącym czyste, jasnoszare tony z białymi akcentami dla stworzenia subtelnej, ale wyrazistej estetyki. Ta wersja kultowego modelu Dunk Low zachowuje klasyczną sylwetkę, oferując jednocześnie nowoczesne podejście do kolorystyki.',
            size: "na zamówienie",
            sizes: [
                {
                    shoe_size: "na zamówienie"
                }
            ]
        },
        {
            mark_logo: mark_logos('./jordan.svg'),
            shoe_image: shoe_images('./Jordan1-High-Chicago.svg'),
            shoe_images: [
                shoe_images('./Jordan1-High-Chicago.svg'),
                shoe_images('./Jordan1-High-Chicago-Front.svg'),
                shoe_images('./Jordan1-High-Chicago-Back.svg'),
                shoe_images('./Jordan1-High-Chicago-Bottom.svg')
            ],
            color: ['white', 'red'],
            mark: "Jordan 1",
            model: "High Chicago Lost & Found",
            info: 'ordan 1 High "Chicago Lost & Found" to specjalna edycja kultowego modelu, która oddaje hołd jednemu z najbardziej ikonicznych kolorystyk w historii sneakersów. Ten model reinterpretauje klasyczne kolory Chicago Bulls – czerwień, biel i czarność – z nową narracją, dodając elementy vintage i noszonego wyglądu.',
            size: "na zamówienie",
            sizes: [
                {
                    shoe_size: "na zamówienie",
                }
            ]
        },
        {
            mark_logo: mark_logos('./nike.svg'),
            shoe_image: shoe_images('./Nike-Dunk-Low-Industrial.svg'),
            shoe_images: [
                shoe_images('./Nike-Dunk-Low-Industrial.svg'),
                shoe_images('./Nike-Dunk-Low-Industrial-Front.svg'),
                shoe_images('./Nike-Dunk-Low-Industrial-Back.svg'),
                shoe_images('./Nike-Dunk-Low-Industrial-Bottom.svg')
            ],
            color: ['white', 'blue'],
            mark: "Nike Dunk Low",
            model: "Industrial Blue Sashiko",
            info: 'Nike Dunk Low "Industrial Blue Sashiko" to unikatowa interpretacja klasycznego modelu, która wyróżnia się dzięki zastosowaniu tradycyjnej japońskiej techniki szycia sashiko. Ten model łączy w sobie głęboki, przemysłowy odcień niebieskiego z charakterystycznymi, białymi przeszyciami, tworząc wyjątkowy i teksturalny wygląd.',
            size: "na zamówienie",
            sizes: [
                {
                    shoe_size: "na zamówienie"
                }
            ]
        },
        {
            mark_logo: mark_logos('./yeezy.svg'),
            shoe_image: shoe_images('./Yeezy-Boost-350-Onyx.svg'),
            shoe_images: [
                shoe_images('./Yeezy-Boost-350-Onyx.svg'),
                shoe_images('./Yeezy-Boost-350-Onyx-Front.svg'),
                shoe_images('./Yeezy-Boost-350-Onyx-Back.svg'),
                shoe_images('./Yeezy-Boost-350-Onyx-Bottom.svg')
            ],
            color: ['black'],
            mark: "Yeezy Boost 350 V2",
            model: "Onyx",
            info: 'Adidas Yeezy Boost 350 V2 "Onyx" prezentuje głęboką, jednolitą kolorystykę w odcieniu czerni, której nazwa nawiązuje do czarnego onyksu – szlachetnego kamienia. Ten model zachowuje charakterystyczną dla Yeezy Boost 350 V2 linie designu, z elastyczną, przewiewną cholewką Primeknit, która zapewnia wygodne dopasowanie.',
            size: "na zamówienie",
            sizes: [
                {
                    shoe_size: "na zamówienie"
                }
            ]
        },
        {
            mark_logo: mark_logos('./nike.svg'),
            shoe_image: shoe_images('./Nike-Dunk-Low-Rose-Whisper.svg'),
            shoe_images: [
                shoe_images('./Nike-Dunk-Low-Rose-Whisper.svg'),
                shoe_images('./Nike-Dunk-Low-Rose-Whisper-Front.svg'),
                shoe_images('./Nike-Dunk-Low-Rose-Whisper-Back.svg'),
                shoe_images('./Nike-Dunk-Low-Rose-Whisper-Bottom.svg')
            ],
            color: ['white', 'pink'],
            mark: "Nike Dunk Low",
            model: "Rose-Whisper",
            info: 'Nike Dunk Low "Rose Whisper" to uroczy i subtelny model, który zyskał uznanie dzięki swojej delikatnej kolorystyce. Buty te wykorzystują pastelowy odcień różu, który przypomina delikatność płatków róż i dodaje lekkości całej konstrukcji. Tradycyjna dla Dunk Low sylwetka jest tu połączona z klasycznymi elementami.',
            size: "39",
            sizes: [
                {
                    shoe_size: 39,
                    price: 700
                }
            ]
        }
    ]
    const toggleShoesTypeVisibility = (category = "") => {
        setSelectedCategory(category);
    };

    const selectProduct = (product) => {
        setSelectedProduct(product);
    };
    // Funkcja do przełączania widoczności filtrów kolorów
    const toggleColorVisibility = () => {
        setIsColorVisible(!isColorVisible);
    };

    // Funkcja do ustawienia wybranego koloru
    const selectColor = (color) => {
        setSelectedColor(color);
    };

    const goBackToList = () => {
        setSelectedProduct(null);
    };

    const filteredProducts = products.filter(product =>
        (selectedCategory === "" || product.mark?.includes(selectedCategory)) &&
        (selectedColor === "" || product.color.includes(selectedColor))
    ).filter(product => {
        // Sprawdzenie, czy rozmiar jest "na zamówienie"
        const isCustomOrder = product.sizes?.some(size => size.shoe_size === "na zamówienie");

        if (isCustomOrder) {
            return true; // Jeśli jest "na zamówienie", akceptuj produkt bez sprawdzania ceny
        } else {
            // Sprawdzanie ceny, jeśli nie jest "na zamówienie"
            const priceCondition = product.sizes?.some(size =>
                size.price >= (minPrice || 0) && size.price <= (maxPrice || Infinity));

            let sizeCondition = false;
            if (typeof product.size === 'string') {
                const parsedSize = parseFloat(product.size);
                if (!isNaN(parsedSize)) {
                    sizeCondition = parsedSize >= (minSize || 0) && parsedSize <= (maxSize || Infinity);
                }
            } else {
                // Upewnienie się, że `product.sizes` istnieje, jest tablicą i zawiera elementy przed wywołaniem `.some()`
                sizeCondition = product.sizes && Array.isArray(product.sizes) && product.sizes.length > 0 && product.sizes.some(size => {
                    const shoeSize = parseFloat(size.shoe_size); // Zakładam, że shoe_size jest stringiem, który można przekształcić na liczbę
                    // Upewniamy się, że przekształcenie shoe_size na liczbę jest poprawne przed porównaniem
                    return !isNaN(shoeSize) && shoeSize >= (minSize || 0) && shoeSize <= (maxSize || Infinity);
                });
            }

            return priceCondition && sizeCondition;
        }
    });

    return (
        <div className="products-page">
            <header>
                <img src={images_header("./logo.png")}/>
                <div className="links_and_lines">
                    <div className="line"></div>
                    <div className="links">
                        <a onClick={() => window.location.href = '/'}>Home</a>
                        <a onClick={() => window.location.href = '/products'}>Products</a>
                        {/*<div className="mark">*/}
                        {/*    <a>Mark</a>*/}
                        {/*    <img src={images_header('./mark-arrow.png')}/>*/}
                        {/*</div>*/}
                        <a>Contact</a>
                        <a className="sale">Sale</a>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="account_and_shopping">
                    <img src={images_header("./account.svg")}/>
                    <img src={images_header("./shopping.svg")}/>
                </div>
            </header>
            <div className="head-lines">
                <div className={`head-line left ${selectedProduct === null ? '' : 'selected'}`}></div>
                <div className={`head-line right ${selectedProduct === null ? '' : 'selected'}`}></div>
            </div>
            {selectedProduct === null ? (
                <div className="filters_and_products">
                    <div className="filter-panel">
                        <div className="category">
                            <h3>Kategoria</h3>
                            <div className="shoes">
                                <div className="name" onClick={() => {
                                    setIsShoesTypeVisible(!isShoesTypeVisible);
                                    if (selectedCategory != null) {
                                        setSelectedCategory("")
                                    }
                                }}>
                                    <h4>Buty</h4>
                                    <img src={images_header('./mark-arrow.png')}/>
                                </div>
                                <ul className={`shoes-info ${isShoesTypeVisible ? "visible" : ""}`}>
                                <li onClick={() => toggleShoesTypeVisibility("Jordan")}
                                className={`option ${selectedCategory === "Jordan" ? 'choose' : ''}`}>Air Jordan</li>
                                <li onClick={() => toggleShoesTypeVisibility("Nike")}
                                    className={`option ${selectedCategory === "Nike" ? 'choose' : ''}`}>Nike</li>
                                <li onClick={() => toggleShoesTypeVisibility("Yeezy")}
                                    className={`option ${selectedCategory === "Yeezy" ? 'choose' : ''}`}>Adidas Yeezy</li>
                                <li onClick={() => toggleShoesTypeVisibility("Adidas")}
                                    className={`option ${selectedCategory === "Adidas" ? 'choose' : ''}`}>Adidas</li>
                            </ul>
                        </div>
                    </div>
                    <div className="filters">
                        <h3>Filtry</h3>
                        <div className="shoes prize">
                            <div className="name" onClick={() => {
                                setIsShoesPriceVisible(!isShoesPriceVisible);
                            }}>
                                <h4>Cena</h4>
                                <img src={images_header('./mark-arrow.png')}/>
                            </div>
                            <div className={`shoes-info ${isShoesPriceVisible ? "visible" : ""}`}>
                                <input type="number" value={minPrice} onChange={e => setMinPrice(e.target.value)}
                                       placeholder="Cena min."/>
                                <input type="number" value={maxPrice} onChange={e => setMaxPrice(e.target.value)}
                                       placeholder="Cena max."/>
                            </div>
                        </div>
                        <div className="shoes size">
                            <div className="name" onClick={() => {
                                setIsShoesSizeVisible(!isShoesSizeVisible);
                            }}>
                                <h4>Rozmiar</h4>
                                <img src={images_header('./mark-arrow.png')}/>
                            </div>
                            <div className={`shoes-info ${isShoesSizeVisible ? "visible" : ""}`}>
                                <input type="number" value={minSize} onChange={e => setMinSize(e.target.value)}
                                       placeholder="Rozmiar min."/>
                                <input type="number" value={maxSize} onChange={e => setMaxSize(e.target.value)}
                                       placeholder="Rozmiar max."/>
                            </div>
                        </div>
                        <div className="shoes color">
                            <div className="name" onClick={toggleColorVisibility}>
                                <h4>Kolor</h4>
                                <img src={images_header('./mark-arrow.png')}/>
                            </div>
                            <div className={`shoes-info colors ${isColorVisible ? "visible" : ""}`}>
                                {/* Przykład jak wyświetlić kolory. Możesz dostosować do swoich potrzeb */}
                                <button className="back" onClick={() => selectColor("")}><a>X</a></button>
                                {["white", "black", "red", "green", "orange", "blue", "grey", "pink", "brown"].map((color, index) => (
                                    <button key={index} onClick={() => selectColor(color)}
                                            style={{ backgroundColor: `${color}`,
                                                border: `2px solid white`}}></button>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/*<button onClick={filterProductsByPrice}><a onClick={filterProductsByPrice}>FILTRUJ</a></button>*/}
                </div>
                    <div className="products">
                        {filteredProducts.map((product, index) => (
                            <div className="product" key={index}
                                 onClick={() => selectProduct(product)}>
                                <img src={product.mark_logo}
                                     className={`mark-logo ${product.mark_logo === mark_logos('./yeezy.svg') ? 'yeezy' : ''}`}/>
                                <img src={product.shoe_image} className="shoe-image"/>
                            <div className="product-info">
                                <div className="mark_and_model">
                                    <h3>{product.mark}</h3>
                                    <h3>{product.model}</h3>
                                </div>
                                <h4>Rozmiar {product.size}</h4>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            ) : (
                <div className="product-details">
                    <div className="images">
                        <img className="center-image" src={selectedMainImage || selectedProduct.shoe_image} alt="Selected Shoe"/>
                        <div className="images">
                        {selectedProduct.shoe_images.map((image, index) => (
                                <img key={index} src={image} alt={`Shoe Thumbnail ${index + 1}`}
                                     className={`thumbnail ${selectedMainImage === image ? 'choose' : ''}`}
                                     onClick={() => handleImageClick(image)}/>
                            ))}
                        </div>
                    </div>
                    <div className="info_and_contact">
                        <div className="name_and_info">
                            <h2>{selectedProduct.mark} {selectedProduct.model}</h2>
                            <div className="line"></div>
                            <p>{selectedProduct.info}</p>
                        </div>
                        <div className="sizes">
                            <button className="dropdown-button" onClick={() => setIsOpen(!isOpen)}>
                                Zobacz rozmiary
                                <span className={`arrow ${isOpen ? 'open' : ''}`}></span>
                            </button>
                            {isOpen && (
                                <ul className="dropdown-menu">
                                    {selectedProduct.sizes.map((item, index) => (
                                        <li key={index} className="dropdown-item">
                                            <span className="size">{item.shoe_size}</span>
                                            <span className="price">{item.price} zł</span>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        {/*<button className="contact"><a>SKONTAKTUJ SIĘ</a></button>*/}
                    </div>
                    <button className="goBack" onClick={() => {
                        goBackToList();setIsOpen(!isOpen);
                    }}>X</button>
                </div>
            )}
            <footer>
                <img src={images_header("./logo.png")}/>
                <div className="links">
                    <div className="contact">
                        <h4>Contact</h4>
                        <div>
                            <img src={images('./email.png')} alt="Email icons created
                                by Freepik - Flaticon https://www.flaticon.com/free-icons/email"/>
                            <p>sniks.plug@gmail.com</p>
                        </div>
                    </div>
                    <div className="social-media">
                        <h4>Social media</h4>
                        <div>
                            <img src={images('./instagram.png')} alt="Instagram
                                icons created by Freepik - Flaticon https://www.flaticon.com/free-icons/instagram"/>
                            <p>sniks.plug</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Products